'use strict';

Gri.module({
  name: 'section-zeta',
  ieVersion: null,
  $el: $('.section-zeta'),
  container: '.section-zeta',
  fn: function () {
    
  }
});
